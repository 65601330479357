<script setup lang="ts">
import type { ProductCategory } from '~/types/ProductCategory';

// MODEL
const activeProductCateType = defineModel<ProductCategory | null>({ required: true, default: null });

// PROPS
interface Props {
    data: ProductCategory[];
}
const props = defineProps<Props>();
const { data } = toRefs(props);

// EMIT
type Emits = {
    edit: [category: ProductCategory];
    delete: [category: ProductCategory];
};
defineEmits<Emits>();
</script>
<template>
    <div class="relative shadow-md overflow-x-auto rounded-xl">
        <table class="w-full text-left rtl:text-right">
            <thead class="text-lg text-white uppercase bg-primary-500 dark:bg-primary-500">
                <tr>
                    <th scope="col" class="px-6 py-3">ชื่อประเภทบัญชีของสินค้า</th>
                    <th scope="col" class="px-6 py-3" />
                </tr>
            </thead>
            <tbody v-for="category in data" :key="category.id" :class="{ '!bg-primary-500 !bg-opacity-20': activeProductCateType?.id === category.id }" @click="activeProductCateType = category">
                <tr class="bg-white border-b dark:bg-neutral-800 dark:border-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-600">
                    <td class="px-6 py-1 font-medium text-neutral-900 whitespace-nowrap dark:text-white">{{ category.name }}</td>
                    <td class="flex justify-end px-6 py-1">
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button variant="ghost" class="px-2">
                                    <IconCSS name="material-symbols:more-vert" size="1.25rem" />
                                </Button>
                            </DropdownMenuTrigger>

                            <DropdownMenuContent>
                                <DropdownMenuGroup>
                                    <DropdownMenuItem @click="$emit('edit', category)"><IconCSS name="material-symbols:edit-outline-rounded" size="1.25rem" class="mr-1" /> แก้ไข</DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
