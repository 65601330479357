import type { GetAllApiMetadata, GetAllApiMetadataWithPaginate, GetApiMetadata, GetApiOptions, Paginate } from '~/types/Global';
import type { ProductCategory, ProductCategoryPayload } from '~/types/ProductCategory';

const defaultGetApiMetadata: GetAllApiMetadata = { search: '', filter: {}, include: [], orderBy: {} };

const defaultGetApiOptions: GetApiOptions = {};

const BASE_URL = '/v1/product-categories';

export function useProductCategory() {
    async function getAll(metadata: GetAllApiMetadata = defaultGetApiMetadata, options: GetApiOptions = defaultGetApiOptions): Promise<ProductCategory[]> {
        try {
            const { data, error } = await useApiFetch<ProductCategory[]>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getAllWithPaginate(metadata: GetAllApiMetadataWithPaginate, options: GetApiOptions = defaultGetApiOptions): Promise<Paginate<ProductCategory[]>> {
        try {
            const { data, error } = await useApiFetch<Paginate<ProductCategory[]>>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return { prevPage: 0, nextPage: 0, data: [], currentPage: 0 };
        }
    }

    async function getById(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<ProductCategory | null> {
        try {
            const { data, error } = await useApiFetch<ProductCategory>(`${BASE_URL}/${id}`, {
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function create(payload: ProductCategoryPayload, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<ProductCategory | null> {
        try {
            const { data, error } = await useApiFetch<ProductCategory>(`${BASE_URL}/create`, {
                method: 'POST',
                body: payload,
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function update(
        id: string,
        payload: Partial<ProductCategory>,
        metadata: GetApiMetadata = defaultGetApiMetadata,
        options: Pick<GetApiOptions, 'errorCallback'> = {},
    ): Promise<ProductCategory | null> {
        try {
            const { data, error } = await useApiFetch<ProductCategory>(`${BASE_URL}/${id}`, {
                method: 'PATCH',
                body: payload,
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function deleteCategory(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<ProductCategory | null> {
        try {
            const { data, error } = await useApiFetch<ProductCategory>(`${BASE_URL}`, {
                method: 'DELETE',
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    return {
        getAll,
        getById,
        create,
        update,
        getAllWithPaginate,
        delete: deleteCategory,
    };
}
